import { Card, Grid, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CommonCodeInfoReturn from "components/commonCode/CommonCodeInfoReturn";
import { useEffect,  useState } from "react";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import assetTableData from "../tables/data/AssetTableData";
import Table from "examples/Tables/AssetsTable";

import { useRecoilValue } from "recoil";
import listUpdate from "atom/data/listUpdate";
import getAssetList from "api/asset/getAssetList";
import { Download, InsertDriveFile } from "@mui/icons-material";
import SoftButton from "components/SoftButton";
import userAll from "api/user/userAll";
import allCommonCodeList from "api/commonCode/allCommonCodeList";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";


function Tables() {
    const { columns } = assetTableData;

    const [assetList, setAssetList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [mem_name, setMem_name] = useState('all');    
    const [ass_cate, setAss_cate] = useState('all');
    const isUpdate = useRecoilValue(listUpdate);
    const [excelClick, setExcelClick] = useState(false);
    const [cateList, setCateList] = useState([]);
    const [commonCodeMapData, setcommonCodeMapData] = useState({});

    useEffect(() => {
        //자산 목록
        getAssetList()
          .then(response => {        
            setAssetList(response.data);
          })
          .catch(error => {
            console.log(error);
          });
        
        //임직원 목록
        userAll()
          .then((response) => {
            setNameList(response.data);            
          }); 
        
        //공통코드 변환용
        allCommonCodeMap()
          .then((response) => {
           setcommonCodeMapData(response.data)
          })         
      }, [isUpdate]);

      const { data } = useQuery('allCommonCodeList',allCommonCodeList,{ 
        select : (data) => {
            return data.data
        },
        onSuccess : (data)=>{
          const assetCateList = data
          .filter(item => item.cc_category === 'ASSET')
          .map(item => ({ cate: item.cc_name, info: item.cc_info }));          
        setCateList(assetCateList);
        }
      })      
  
  //엑셀 다운로드 버튼 클릭 시 렌더 여부
  const clickExcelBtn = () => {
     setExcelClick(prevState => !prevState);
    }

    
    // 물품 카테고리 별로 정렬 후 렌더
    const renderAssetList = (assetList) => {
      assetList.sort((a, b) => a.ass_cate.localeCompare(b.ass_cate));
    
      return assetList.map((asset, idx) => {        
        return {
          구분: renderTypography(<CommonCodeInfoReturn init={asset.ass_cate}/>),
          제조년월: renderTypography(asset.ass_date),
          제조사: renderTypography(asset.ass_com),
          모델번호: renderTypography(asset.ass_name),
          SN: renderTypography(asset.ass_sn),
          CPU: renderTypography(asset.ass_cpu),
          RAM: renderTypography(asset.ass_ram),
          크기: renderTypography(asset.ass_size),
          SSDTYPE: renderTypography(asset.ass_ssd_type),
          SSD: renderTypography(asset.ass_ssd),
          HDD: renderTypography(asset.ass_hdd),
          해상도: renderTypography(asset.ass_res),
          OS: renderTypography(asset.ass_os),
          비고: renderTypography(asset.ass_memo),
          현재사용자: renderTypography(asset.mem_name),
        };
      });
    };

    //엑셀 헤더 이름
    const csvHeaders = columns.map((column) => ({
      label: column.name,
      key: column.name,
      align: "center",
      style: { fontWeight: "bold" }, 
    }));

    //엑셀에 들어갈 데이터 목록
    const forExcelList = (assetList) => {
      return assetList.filter((asset) => {
        if (mem_name !== "all" && asset.mem_name !== mem_name) {
          return false;
        }
        if (ass_cate !== "all" && asset.ass_cate !== ass_cate) {
          return false;
        }
        return true;
      }).map((asset, idx) => {
        return {
          구분: commonCodeMapData[asset.ass_cate].info,
          제조년월: asset.ass_date,
          제조사: asset.ass_com,
          모델번호: asset.ass_name,
          SN: asset.ass_sn,
          CPU: asset.ass_cpu,
          RAM: asset.ass_ram,
          크기: asset.ass_size,
          SSDTYPE: asset.ass_ssd_type,
          SSD: asset.ass_ssd,
          HDD: asset.ass_hdd,
          해상도: asset.ass_res,
          OS: asset.ass_os,
          비고: asset.ass_memo,
          현재사용자: asset.mem_name,
        };
      });
    };
    
   
   const renderTypography = (text) => {
     return <Typography sx={{ fontSize: "14px"}}>{text}</Typography>;
    };
     

    return (
        <DashboardLayout>
        <DashboardNavbar />
        <Grid>
        <SoftBox sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftButton variant="gradient" color="dark" onClick={clickExcelBtn}>
            <InsertDriveFile sx={{ marginLeft: -1 }} />
            &nbsp;엑셀 다운로드
          </SoftButton>
        </SoftBox>
        {excelClick && 
        <SoftBox>
         <Grid mb={2}>
          <Typography variant="h6">다운로드 범위</Typography>
          </Grid>          
          <Grid container>
           <Grid item lg={2} xs={1.5}>
            <select onChange={(e) => setAss_cate(e.target.value)} style={{width : "90%", height:"30px"}}>
            <option value="all"> - 구분 -</option>    
            {cateList.map(cate => (        
              <option value={cate.cate}>{cate.info}</option>
              ))}
            </select>        
            </Grid>         
            <Grid item lg={2} xs={1.5}>
            <select onChange={(e) => setMem_name(e.target.value)} style={{width : "90%", height:"30px"}}>
            <option value="all"> - 사용자 -</option>    
           {nameList.map(nl => (        
              <option value={nl.memName + " " + (nl.memRank ? commonCodeMapData[nl.memRank].info : "")}>{nl.memName}&nbsp;<CommonCodeInfoReturn init={nl.memRank}/></option>
              ))}
            </select>        
            </Grid>
            <Grid>
            <CSVLink                                      
                    data={forExcelList(assetList)}
                    headers={csvHeaders}
                    filename={(mem_name === "all" ? "임직원" : mem_name) + " " + (ass_cate === "all"? "전체 물품" : commonCodeMapData[ass_cate].info)}
                    target="_blank"                  
                  >
            <Download sx={{ marginLeft: -1 }} />
              &nbsp; 다운로드
          </CSVLink>
              </Grid> 
          </Grid>           
          </SoftBox>        
          }    
      </Grid>
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography variant="h6">물품 관리 대장</SoftTypography>
              </SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table columns={columns} rows={renderAssetList(assetList)} sx={{whiteSpace : "nowrap"}}/>               
              </SoftBox>
            </Card>
          </SoftBox>
        </SoftBox>       
        <Footer />
      </DashboardLayout>
    )
}

export default Tables;
