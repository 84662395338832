// 서버개발용
export const myIpAddress = "https://nasdoc.nineonesoft.com/nasdoc";

// 로컬개발용
// export const myIpAddress = "http://localhost:8080";
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

// 서버개발용
export const OAUTH2_REDIRECT_URI = 'https://nasdoc.nineonesoft.com/login/success'

// 로컬개발용
// export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/login/success'


export const GOOGLE_AUTH_URL = myIpAddress + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = myIpAddress + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = myIpAddress + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
